@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,.home1,
.section3,
.blog,
.about-us,
.plots,
.submit-plot{
  display: flex;
  height: 90vh;
}


.home {
  background-image: url('./images/bg-1.png');
  background-size: cover;
  background-repeat: no-repeat;

}

.section3 {
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;

}

.section-heading {
  position: relative;
  top: 200px;
  left: 450px;
  width: 948px;
  height: 77px;
  text-align: center;
  font: normal normal normal 64px/77px Roboto;
  letter-spacing: 0px;
  color: #404597;
  opacity: 1;
}

.section-subheading {
  margin-right:100px;
  position: relative;
  font-size: 100px;
  top: 300px;
  left: 0px;
  width: 895px;
  height: 109px;
  text-align: center;
  font: normal normal normal 35px/42px Roboto;
  letter-spacing: 0px;
  color: #404597;
  opacity: 1;
}


.blog {
  background-image: url('./images/img-3.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.about-us {
  background-image: url('./images/img-4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.submit-plot {
  background-image: url('./images/img-7.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #4953CA !important;
}

.bgimage {
  width:100%;
  height:600px;
  background-image: url('./images/bg-1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
  
}
.bgimage h5 {
  color:white;
  position: relative;
  top: 150px;
  padding-left: 60px;
  font: normal normal normal 60px/70px Roboto;
}

.bgimage button {
  position: relative;
  top:170px;
  left: -50px;
  
}

.bgimage img {
  position: relative;
  top: -11px;
  left: 20px;
  
}



.whyInvest{
  width:100%;
  height:600px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.whyInvest h2 {
  color: #2f3153;
  position: relative;
  top: 120px;
  padding-left: 60px;
  font: normal normal normal 40px/60px Roboto;
  text-align: center;
  padding-bottom: 20px;
}

.whyInvest p{
    color: #404597;
    position: relative;
    top: 110px;
    padding-left: 60px;
    font: normal normal normal 22px/40px Roboto;
    text-align: center;
    padding-bottom: 30px;
  
}

.whyInvest img {
  position: relative;
  width: 70px;
  height: 40px;
}

.whySmart {
  width:100%;
  height:800px;
  background-image: url('./images/bg-2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
  
}

.whySmart h2 {
  color: #030F5B;
    position: relative;
    top: 50px;
    padding-left: 60px;
    font: normal normal normal 40px/70px Roboto;
    text-align: center;
   
    
  
}

.whySmart p{
    color: #030F5B;
    position: relative;
    top: 60px;
    padding-left: 60px;
    font: normal normal normal 22px/40px Roboto;
    text-align: center;
    padding-bottom: 30px;
  
}

.whySmart img {
    position: relative;
    width: 70px;
    height: 40px;
    

}

.card {
  display: flex;
  
  left: 200px;
  width: 300px;
  height: 240px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 79px;
  opacity: 0.73;
  margin-top: 50px;
  

}


.card-title{
  margin-top: -260px;
  position: relative;
  left: 180px;
  width: 100px;
  height: 100px;
  background: #EA9362 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  transition: width 2s, height 4s;
}

.card-title:hover {
  display : block;
  width: 300px;
  height: 240px;
}

.card-title:hover + .card-subtitle{
  display: none;
}






.card-title img{
  position: relative;
  top: 30px;
  z-index: 1;
}

.card-subtitle{
    color: #030F5B;
    position: relative;
    left: 170px;
    top: 20px;
    padding-left: 60px;
    font: normal normal normal 22px/40px Roboto;
    text-align: center;
    padding-bottom: 30px;
}

.line {
  position: relative;;
  top: 240px;
  left: 200px;
  width: 670px;
  border: 2px solid #EA9362;
  opacity: 1;
  text-align: center;
}

.card-state {
  width: 130px;
  height: 130px;
  background: #EA9362 0% 0% no-repeat padding-box;
  border-radius: 27px;
  opacity: 1;
  text-align: center;
  position: relative;
  top:175px;
  left:100px;
}

.card-state img{
  position: relative;
  top: 35px;
}

.ourApproach{
  width:100%;
  height:1070px;
  background: transparent linear-gradient(51deg, #333A94 0%, #343A92 100%) 0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.ourApproach h2 {
  color: #FFFFFF;
  position: relative;
  top: 70px;
  padding-left: 60px;
  font: normal normal normal 40px/70px Roboto;
  text-align: center;
  padding-bottom: 20px;
}

.ourApproach p{
    color: #FFFFFF;
    position: relative;
    top: 60px;
    font: normal normal normal 22px/40px Roboto;
    text-align: center;
    padding-bottom: 30px;
  
}

.ourApproach img {
  position: relative;
  width: 70px;
  height: 40px;
}

.box {
  top: 80px;
  left: 35px;
  width: 280px;
  height: 350px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 35px #0000001A;
  border: 2px solid #EA9362;
  border-radius: 12px;
  opacity: 1;
  position:absolute;
}

.box-top {
  top: 80px;
  left: 35px;
  width: 280px;
  height: 140px;
  background: #EA9362 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 12px;
  position:absolute;
}

.box p{
  position: relative;
  top: 200px;
  text-align: center;
  padding-right: 100px;
  padding-left: 40px;
  width: 335px;
  height: 105px;
  text-align: center;
  font: normal normal normal 16px/30px Roboto;
  letter-spacing: 0px;
  color: #030F5B;
  opacity: 1;

}

.gallery{
  position: relative;
  top:550px;
  padding-left: 100px;
  padding-right: 100px;
  
  
}

.gallery span{
  
  font: normal normal normal 22px/40px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  position: relative;
  left:10px;
  
}

.gallery img{
  padding-top: 30px;
  width:180px;
  height:260px;
  
}
.label {
    z-index: 0;
    position: relative;
    top:-219px;
    left:7px;
    padding-top: 160px;
    background: transparent linear-gradient(180deg, #0B0B0C00 0%, #0B0B0C 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    height: 210px;
    background-size: cover;
    color: white;
    text-align: center;
    border-radius: 8px;
    opacity: 0.75;
    font: normal normal normal 22px/40px Roboto;
}

.column-1 img{
  width:350px;
}

.howItWorks{
  width:100%;
  height:600px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.howItWorks h2 {
  color: #404597;
  position: relative;
  top: 60px;
  padding-left: 60px;
  font: normal normal normal 40px/60px Roboto;
  text-align: center;
  
}

.howItWorks p{
    color: #404597;
    position: relative;
    top: 110px;
    padding-left: 60px;
    font: normal normal normal 22px/40px Roboto;
    text-align: center;
    padding-bottom: 30px;
  
}

.border-box{
  position: relative;
  top: 130px;
  left: 30px;
  width: 300px;
  height: 300px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 4px solid #333A94;
  border-radius: 25px;
  opacity: 1;
  padding-right: 30px;
}

.box-number{
  position: relative;
  top: 7px;
  left: 200px;
  width: 85px;
  height: 85px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 17px;
  opacity: 1;
}

.box-number p{
  position: relative;
  top: 7px;
  left:-44px;
  font: normal normal normal 50px/68px Roboto;
  letter-spacing: 0px;
  color: #EA9362;
}

.box-title{
  position: relative;
  top: 10px;
  left: 20px;
  width: 100%;
  height: 85px;
  font: normal normal normal 30px/40px Roboto;
  letter-spacing: 0px;
  color: #333A94;
}

.box-body span {
  position: relative;
  top: -20px;
  text-align: center;
  left: 20px;
  height: 85px;
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  color: #333A94;
}

.carouselSection{
  width:100%;
  height:800px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.carouselSection h2 {
  color: #404597;
  position: relative;
  top: 120px;
  padding-left: 60px;
  font: normal normal normal 40px/60px Roboto;
  text-align: center;
  padding-bottom: 80px;
}



.carousel {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-left: 100px;
  margin-right: 100px;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px;
  width: 100%;
  position: relative;
  background: #333A94 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 35px #00000038;
  border-radius: 10px;
  margin: 0 25px;
  
}

.item img{
  width: 295px;
  position: relative;
  top: -118px;
}

.item p{
  position: absolute;
  top: 190px;
  font: normal normal normal 13px/17px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  
}

.item h1{
  position: absolute;
  top: 220px;
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  
}

.item span{
  position: absolute;
  top: 270px;
  font: normal normal normal 12px/20px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  
}

.item h2{
  position: absolute;
  text-align: center;
  top: 350px;
  left: 50px;
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
}






.gallery-box {
  top: 5817px;
  left: 273px;
  width: 437px;
  height: 575px;
  background: #333A94 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 35px #00000038;
  border-radius: 17px;
  opacity: 1;
}


.newsletter{
  
    background-image: url(./images/newsletterBg.jpg);
    width: 100%;
    height: 470px;
    background-size: cover;
    color: white;
    padding: 20px;
  

}


.newsletter h2 {
 
  color: #FFFFFF;
  position: relative;
  top: 50px;
  padding-left: 60px;
  font: normal normal normal 40px/60px Roboto;
  text-align: center;
  padding-bottom: 20px;
  
}

.newsletter p{
    color: #FFFFFF;
    position: relative;
    top: 30px;
    padding-left: 60px;
    font: normal normal normal 22px/40px Roboto;
    text-align: center;
    padding-bottom: 30px;
  
}

.formDiv {
  position: relative;
  top: 40px;
  left: 200px;
  width: 900px;
}

.formDiv label{
  font: normal normal normal 17px/20px Roboto;
}

.formDiv button{
  position: relative;
  top: 25px;
  left: 300px ;
}

.footer{
  position: relative;
  top: 3px;
  background-image: url(./images/footerBg.jpg);
  width: 100%;
  height:400px;
  
  background-size: cover;
  color: white;
}

.footer h5{
  color: #FFFFFF;
    position: relative;
    top: 20px;
    
    font: normal normal normal 22px/30px Roboto;
    
}

.footer li{
  color: #FFFFFF;
    position: relative;
    top: 50px;
    
    font: normal normal normal 15px/30px Roboto;
    
}

.copyrights{
  position: relative;
  margin-top: 100px;
  top: -350px;
  text-align: center;
  background-color: black;
  padding: 0.5rem !important;
  font: normal normal normal 15px/20px Roboto;
}

.footer-form{
  width: 440px;
  height: 270px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 19px;
  opacity: 0.43;
}
.footer-div {
  position: relative;
  top: -270px;
  width: 330px;
  left: 55px;
}

.footer-div button {
  width: 200px;
  height: 50px;
  position: relative;
  left: 60px;
}

/** About Us **/

.aboutBg {
  width:100%;
  height:520px;
  background-image: url('./images/aboutUsMainBg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
  
}

.aboutUs{
  width:100%;
  height:800px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.aboutUs h2 {
  color: #404597;
  position: relative;
  top: 120px;
  padding-left: 60px;
  font: normal normal normal 40px/60px Roboto;
  text-align: center;
  padding-bottom: 20px;
}

.aboutUs p{
    color: #404597;
    position: relative;
    top: 110px;
    padding-left: 60px;
    font: normal normal normal 22px/35px Roboto;
    text-align: center;
    padding-bottom: 30px;
  
}

.aboutUs span{
  color: #404597;
  position: relative;
  top: 200px;
  padding-left: 30px;
  font: normal normal normal 22px/35px Roboto;
  text-align: center;
  padding-bottom: 30px;

}

.locationBg img {
  margin-left: -90px;
  text-align: center;
  width: 450px;
  height: 350px;
  
}

/** End Of About Us **/


/** Plots **/
.plots{
  width:100%;
  height:1600px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.plots h2 {
  color: #404597;
  position: relative;
  top: 50px;
  padding-left: 60px;
  font: normal normal normal 40px/60px Roboto;
  text-align: center;
  padding-bottom: 20px;
}

.plotsBox {
  position: relative;
  top:90px;
  
  width: 310px;
  height: 340px;
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.048), rgba(0, 0, 0, 0.534)),
  url('./images/plot-1.png');
  opacity: 1;
}

.plotsBox button{
  position: relative;
  width:310px;
  left: 0.5px;
  top: 210px;
  
}

.plotsBox p{
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  top:200px;
  text-align: left;
  font: normal normal normal 20px/25px Roboto;
  color: #FFFFFF;
  
}

.plotsLocation {
  position: relative;
  width: 201px;
  height: 46px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 14px 0px;
  opacity: 1;
}

.plotsBox span{
  position: relative;
  text-align: left;
  top: 4px;
  left: 45px;
  font: normal normal normal 20px/30px Roboto;
  letter-spacing: 0px;
  color: #030F5B;
}

.plotsBox img{
  position: relative;
  left: 40px;
}

.spacer5 {
  height: 150px;
}

.section-form img{
  top: 40px;
  position: relative;
  
  width: 1100px;
  height: 600px;
}

.form-div h5{
  position: relative;
  text-align: center;
 
  font: normal normal normal 20px/30px Roboto;
  letter-spacing: 0px;
  color: #030F5B;
}

.form-div {
  position: relative;
  top: -470px;
  width: 330px;
  left: 100px;
}

.form-div button {
  width: 200px;
  height: 50px;
  position: relative;
  left: 60px;
}

.plotsSection2{
  width:100%;
  height:800px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.plotsSection2 h2 {
  color: #404597;
  position: relative;
  top: 50px;
  padding-left: 60px;
  font: normal normal normal 40px/60px Roboto;
  text-align: center;
  padding-bottom: 20px;
}

.addImage {
  position: relative;
  top: -420px;
  width: 160px;
  height: 120px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.addIcon img{
  margin-left: 65px;
  margin-top: -320px;
  width: 30px;


}

.addIcon p{
  position: relative;
  top: 85px;
  text-align: center;
  font: normal normal normal 17px/25px Roboto;
  color: #808080;
}

.spacer6 {
  height: 70px;
}

.faqsSection3{
  width:100%;
  height:530px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.faqsSection3 img {
  width: 550px;
  margin-left: -60px;
  
}

.faqs {
	width: 650px;
	max-width: 768px;
	margin: 0 auto;
  padding: 15px;
  margin-left: 50px;

}

.faqs .faq {
	margin: 15px;
	padding: 15px;
	background-color: #FFF;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faqsSection3 button {
  margin-left: 80px;
  width: 250px;
}

.faqs .faq .faq-question {
	position: relative;
	padding-right: 80px;
  font: normal normal normal 20px/25px Archivo;
  color: #2D4059;
	transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;

	background-image: url('./images/icons/plus-1.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
  overflow-y: hidden;
  font: normal normal normal 16px/25px Archivo;
  color: #525f6e;
	transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
	margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
	transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
  font: normal normal normal 16px/25px Archivo;
  color: #525f6e;
	max-height: 1000px;
	opacity: 1;
}

/** Blog Page **/

.blogImage img {
  position: relative;
  width: 100%;
  margin-left: 70px;
  margin-top: 30px;
}

.blogCarousel{
  width:100%;
  height:800px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.blogCarousel h2 {
  color: #404597;
  position: relative;
  top: 120px;
  padding-left: 60px;
  font: normal normal normal 40px/60px Roboto;
  text-align: center;
  padding-bottom: 80px;
}



.carousel {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-left: 100px;
  margin-right: 100px;
}

.blogItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px;
  width: 100%;
  position: relative;
  background: #333A94 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 35px #00000038;
  border-radius: 10px;
  margin: 0 25px;

}

.blogItem img{
  width: 478px;
  position: relative;
  top: -120px;
  border-radius: 37px;
}

.blogItem p{
  position: absolute;
  top: 190px;
  font: normal normal normal 13px/17px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-left: -315px;
  
}

.blogItem h1{
  position: absolute;
  top: 210px;
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-left: -300px;

}

.blogItem span{
  position: absolute;
  top: 250px;
  font: normal normal normal 12px/20px Roboto;
  letter-spacing: 0px;
  line-height: 20px;
  color: #FFFFFF;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;

}

.blogItem button{
  position: absolute;
  text-align: left;
  
  left: 50px;
  font: normal normal normal 15px/20px Roboto;
  letter-spacing: 0px;
  background: #50DC3A 0% 0% no-repeat padding-box;
  padding: 10px 25px 10px;
  border-radius: 6px;
}

.whatsappButton img{
  
  width: 30px;
  margin-left: -400px;
}

.blogSection2{
  width:100%;
  height:530px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.blogBox {
  top: 80px;
  left: 35px;
  width: 280px;
  height: 350px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 35px #0000001A;
  border-radius: 12px;
  opacity: 1;
  position:absolute;
}

.blogBox-top {
  top: 80px;
  left: 35px;
  width: 280px;
  height: 140px;
  background:#333A94 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 12px;
  position:absolute;
}


.blogBox span{
  position: absolute;
  top: 220px;
  font: normal normal normal 12px/20px Roboto;
  letter-spacing: 0px;
  line-height: 20px;
  color: #333A94;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;

}


.blogBox p{
  position: relative;
  top: 180px;
  margin-left: -55px;
  padding-right: 100px;
  padding-left: 40px;
  width: 335px;
  height: 105px;
  text-align: center;
  font: normal normal normal 16px/30px Roboto;
  letter-spacing: 0px;
  color: #030F5B;
  opacity: 1;

}


/** End Of Blog Page **/

/** Plots Details Page **/

.plotsMainBanner {
  width:100%;
  height:600px;
  background-image: url('./images/plotsDetailMainBg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
  
}
.plotsMainBanner h5 {
  color:white;
  position: relative;
  top: 150px;
  padding-left: 60px;
  font: normal normal normal 60px/70px Roboto;
}

.plotsMainBanner button{
  margin-top: 170px;
  margin-left: 60px;
}

.plotsDetailSection2{
  width:100%;
  height:700px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.plotsDetailSection2 h2 {
  color: #404597;
  position: relative;
  top: 50px;
  padding-left: 130px;
  font: normal normal normal 40px/60px Roboto;
  text-align: center;
  padding-bottom: 20px;
}

.plotsDetailSection2 img{
  width: 400px;
  position: relative;
  top: 70px;
}

.pd-s2-contents img{
  width: 20px;
  margin-left: 50px;
}

.pd-s2-contents h1{
  font: normal normal normal 17px/25px Roboto;
  color: #0B0B0C;
  margin-left: 90px;
  position: relative;
  top: 47px;
}

.pd-s2-contents span{
  font: normal normal normal 14px/15px Roboto;
  color: #535353;
 
}

.plotsDetailSection3{
  width:100%;
  height:700px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.plotsDetailSection3 h2 {
  color: #404597;
  position: relative;
  top: -20px;
  font: normal normal normal 40px/60px Roboto;
  margin-bottom: -160px;
}

.plotsDetailSection3 img{
  width: 570px;
  position: relative;
  top: 70px;
}

.plotsDetailSection3 .pd-s2-contents img{
  width: 20px;
  margin-left: 50px;
}

.villaIcon img{
  width: 45px;
  margin-left: 360px;
  margin-top: -290px;
}

.costOfVilla{
  position: relative;
  padding-left: 20px;
  padding-right:20px;
  top: 120px;
  width: 500px;
  height: 270px;
  background: #4A4847 0% 0% no-repeat padding-box;
  opacity: 1;
}

.costOfVilla p{
  position: relative;
  top: 25px;
  font: normal normal normal 20px/30px Roboto;
  text-align: center;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.costOfVilla table{
  position: relative;
  text-align: left;
  top: 35px;
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

table{
  margin-bottom: 30px;
}

.costOfApartment{
  position: relative;
  padding-left: 20px;
  padding-right:20px;
  top: 120px;
  width: 500px;
  height: 230px;
  background: #8D8D8D 0% 0% no-repeat padding-box;
opacity: 1;
}

.costOfApartment p{
  position: relative;
  top: 25px;
  font: normal normal normal 20px/30px Roboto;
  text-align: center;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.costOfApartment table{
  position: relative;
  text-align: left;
  top: 35px;
  font: normal normal normal 14px/20px Roboto;
  
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.masterPlan{
  width:100%;
  height:600px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
}

.masterPlanBg{
  width: 1360px;
  height: 319px;
  margin-left: -130px;
  background: #333A94 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}

.masterPlanBg h2 {
  color: #FFFFFF;
  position: relative;
  top: 50px;
  font: normal normal normal 40px/60px Roboto;
  margin-bottom: -100px;
  margin-left: 550px;
}

.masterPlanBg .masterPlanIcon img{
  width: 45px;
  margin-left: 230px;
  margin-top: -150px;
}

.masterPlanBg img{
  width: 700px;
  margin-left: 330px;
  margin-top: 130px;
}

.masterPlanBg button{
 
  margin-left: 530px;
  margin-top: -25px;
}


.plotsDetailSection4{
  width:100%;
  height:750px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
  padding-left: 60px;
  padding-right: 60px;
}

.plotsDetailSection4 h2 {
  color: #404597;
  position: relative;
  top: 20px;
  left: 16px;
  font: normal normal normal 40px/60px Roboto;
  margin-bottom: -100px;
}

.spacer7 {
  height: 20px;
}

.photoGrid{
  position: relative;
  top:160px;
}




.plotsDetailSection5{
  width:100%;
  height:900px;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size:cover;
  padding-left: 70px;
  padding-right: 70px;
}

.plotsDetailSection5 h2 {
  color: #404597;
  position: relative;
  top: 50px;
  font: normal normal normal 40px/60px Roboto;
  margin-bottom: -100px;
}

.plotsDetailSection5 h1 {
  color: #404597;
  position: relative;
  top: 165px;
  font: normal normal normal 40px/60px Roboto;
  margin-bottom: -100px;
}


.plotsDetailSection5 span {
  color: #535353;
  position: relative;
  top: 100px;
  font: normal normal normal 16px/24px Roboto;
  margin-bottom: -100px;
}

.plotsDetailSection5 img{
  width: 570px;
  position: relative;
  top: 70px;
}



.locationIcon img{
  width: 35px;
  margin-left: 170px;
  margin-top: -280px;
}

.bulbIcon img{
  width: 45px;
  margin-left: 270px;
  margin-top: -280px;
}

.projectDetails{
  position: relative;
  padding-left: 20px;
  padding-right:20px;
  top: 230px;
  width: 500px;
  height: 400px;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  opacity: 1;
}

.projectDetails p{
  position: relative;
  top: 25px;
  font: normal normal normal 20px/30px Roboto;
  text-align: center;
  letter-spacing: 0px;
  color: #0B0B0C;
  opacity: 1;
}

.projectDetails table{
  position: relative;
  text-align: left;
  top: 35px;
  font: normal normal normal 14px/20px Roboto;
  
  letter-spacing: 0px;
  color: #0B0B0C;
  opacity: 1;
}

.mapImage{
  margin-top: -100px;
}

.mapImage img{
  width: 530px;
}





/** End Of Plots Details Page **/











@media only screen and (max-width: 600px) {
  .bgimage {
    width:100%;
    height:400px;
    background-image: url('./images/bg-1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    
  }
  .bgimage h5 {
    color:white;
    position: relative;
    top: 120px;
    padding-left: 60px;
    font-size: 30px;
  }

  .whySmart {
    width:100%;
    height:400px;
    background-image: url('./images/bg-2.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    
  }
  
  .whySmart h2 {
    color: #030F5B;
      position: relative;
      top: 50px;
      padding-left: 60px;
      font-size: 30px;
      text-align: center;
     
      
    
  }
  
  .whySmart p{
      color: #030F5B;
      position: relative;
      top: 70px;
      padding-left: 60px;
      font-size: 20px;
      text-align: center;
      padding-bottom: 30px;
    
  }
  
  .whySmart img {
      position: relative;
      top: 10px;
      width: 70px;
      height: 40px;
      
  
  }
  
}