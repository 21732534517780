
.btn {
  margin-right: 10px;
  left: 1517px;
  width: 280px;
  height: 70px;
  background: #444FBE;
  border-radius: 4px;
  opacity: 1;
  
  text-align: center;
  font: normal normal normal 25px/10px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: #333A94;
  border-radius: 4px;
  transition: all 0.3s ease-out;
  font: normal normal normal 25px/10px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
