@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.navbar {
  background: #fff;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}



.navbar-logo {
  color: #000;
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 50vw;
  justify-content: end;
  margin-right: 3rem;
}

.nav-item {
  position: relative;
  left: 80px;
  text-align: left;
  font: normal normal normal 25px/10px Roboto;
  letter-spacing: 0px;
  color: #030F5B;
  opacity: 1;
  padding-left: 7px;
  padding-right: 7px;
}

.nav-links {
  color: #030F5B;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  
  width: 400px;
  height: 73px;
  border: 6px solid #EA9362;
  border-radius: 13px;
  opacity: 1;
}

.fa-bars {
  color: #000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
   
    height: 90vh;
    position: absolute;
    top: 80px;
    
  }

  .nav-menu.active {
    top: 37px;
  left: 939px;
  width: 73px;
  height: 73px;
  
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #000;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #000;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}
